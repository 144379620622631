exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-game-js": () => import("./../../../src/templates/game.js" /* webpackChunkName: "component---src-templates-game-js" */),
  "component---src-templates-games-js": () => import("./../../../src/templates/games.js" /* webpackChunkName: "component---src-templates-games-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news_post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-ourstory-js": () => import("./../../../src/templates/ourstory.js" /* webpackChunkName: "component---src-templates-ourstory-js" */),
  "component---src-templates-patch-note-js": () => import("./../../../src/templates/patch_note.js" /* webpackChunkName: "component---src-templates-patch-note-js" */),
  "component---src-templates-patch-notes-js": () => import("./../../../src/templates/patch_notes.js" /* webpackChunkName: "component---src-templates-patch-notes-js" */)
}

