import "./src/scss/styles.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Import all CSS dependencies

const addScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.setAttribute(
        "data-domain-script",
        "74a65ad3-5906-421a-aafd-03d203f30d87-test"
    );
    document.body.appendChild(script);
};

export const onClientEntry = () => {
    window.onload = () => {
        //    addScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
        addScript("/scripts/onetrust.js");
    };
};

// export const onRouteUpdate = () => {
//   if (document?.cookie?.includes("hutch-ga=true")) {
//     const currentScripts = document.querySelectorAll("script");

//     if (
//       !some(
//         currentScripts,
//         (script) =>
//           script.src ===
//           "https://www.googletagmanager.com/gtag/js?id=UA-47631299-11"
//       )
//     ) {
//       let scriptAsync = document.createElement("script");
//       scriptAsync.src =
//         "https://www.googletagmanager.com/gtag/js?id=UA-47631299-11";
//       scriptAsync.async = true;
//       document.body.appendChild(scriptAsync);
//     }

//     if (
//       !some(currentScripts, (script) =>
//         script.innerHTML.includes("UA-47631299-11")
//       )
//     ) {
//       let scriptInner = document.createElement("script");
//       scriptInner.innerHTML = `
//         window.dataLayer = window.dataLayer || [];
//                     function gtag(){dataLayer.push(arguments);}
//                     gtag('js', new Date());

//                     gtag('config', 'UA-47631299-11');
//         `;
//       document.body.appendChild(scriptInner);
//     }
//   } else {
//     const docScripts = document?.querySelectorAll("script");

//     const asyncScript = Array.from(docScripts)?.find((script) => {
//       return (
//         script.src ===
//         "https://www.googletagmanager.com/gtag/js?id=UA-47631299-11"
//       );
//     });
//     if (asyncScript) {
//       asyncScript.parentNode.removeChild(asyncScript);
//     }

//     const innerScript = Array.from(docScripts)?.find((script) => {
//       return script.innerHTML.includes("UA-47631299-11");
//     });

//     if (innerScript) {
//       innerScript.parentNode.removeChild(innerScript);
//     }
//   }
// };
